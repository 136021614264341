interface CompaniaConfig {
  asociaSiesa: boolean;
  asociaRedeban: boolean;
}

export abstract class LocalStorage {
  private static readonly llaveCompaniaActiva = 'selectedCompanie';
  private static readonly companiaConfig = 'companyConfig';

  static getCompaniaConfig(): CompaniaConfig {
    const valor =
      localStorage.getItem(this.companiaConfig) ||
      '{asociaSiesa: false, asociaRedeban: false}';
    return JSON.parse(atob(valor));
  }
  static setCompaniaConfig(valor: CompaniaConfig) {
    localStorage.setItem(this.companiaConfig, btoa(JSON.stringify(valor)));
  }

  static getCompaniaActiva() {
    return localStorage.getItem(this.llaveCompaniaActiva) || '';
  }
  static setCompaniaActiva(valor: string) {
    localStorage.setItem(this.llaveCompaniaActiva, valor);
  }
}
